<template>
  <div class="dashboardArea">
    <div class="container">
      <!--  -->

      <div class="row">
        <div
          class="col-6 col-md-3 mb-3 text-center"
          v-if="$can('view client profiles')"
        >
          <nav-tile
            :title="'Clients'"
            :route="'/clients'"
            :icon="'fa-users'"
          ></nav-tile>
        </div>
        <!--  -->
        <div
          class="col-6 col-md-3 mb-3 text-center"
          v-if="$can('manage enquiries')"
        >
          <nav-tile
            :title="'Enquiries'"
            :route="'/enquiries'"
            :icon="'fa-envelope'"
          ></nav-tile>
        </div>

        <!--  -->
        <div class="col-6 col-md-3 mb-3 text-center">
          <nav-tile
            :title="'Emails'"
            :route="'/emails/change'"
            :icon="'fa-inbox-in'"
          ></nav-tile>
        </div>
        <!--  -->
        <div class="col-6 col-md-3 mb-3 text-center">
          <nav-tile
            :title="'SMS'"
            :route="'/sms'"
            :icon="'fa-mobile'"
          ></nav-tile>
        </div>
        <!--  -->
        <div
          class="col-6 col-md-3 mb-3 text-center"
          v-if="$can('view bookings')"
        >
          <nav-tile
            :title="'Bookings'"
            :route="'/bookings/calendar'"
            :icon="'fa-calendar'"
          ></nav-tile>
        </div>

        <!--  -->
        <div class="col-6 col-md-3 mb-3 text-center">
          <nav-tile
            :title="'Create Booking'"
            :route="'/bookings/create'"
            :icon="'fa-calendar-plus'"
          ></nav-tile>
        </div>

        <!--  -->
        <div
          class="col-6 col-md-3 mb-3 text-center"
          v-if="$can('view bookings')"
        >
          <nav-tile
            :title="'Due-ins'"
            :route="'/bookings/due-ins'"
            :icon="'fa-plane'"
          ></nav-tile>
        </div>

        <!--  -->
        <div
          class="col-6 col-md-3 mb-3 text-center"
          v-if="$can('view bookings')"
        >
          <nav-tile
            :title="'Housekeeping'"
            :route="'/bookings/housekeeping'"
            :icon="'fa-bed'"
          ></nav-tile>
        </div>

        <!--  -->

        <div class="col-6 col-md-3 mb-3 text-center">
          <nav-tile
            :title="'Talks'"
            :route="'/talks'"
            :icon="'fa-microphone'"
          ></nav-tile>
        </div>

        <!--  -->
        <div
          class="col-6 col-md-3 mb-3 text-center"
          v-if="$can('view health questionnaires')"
        >
          <nav-tile
            :title="'Consultations'"
            :route="'/consultations'"
            :icon="'fa-user-md'"
          ></nav-tile>
        </div>

        <!--  -->
        <div class="col-6 col-md-3 mb-3 text-center">
          <nav-tile
            :title="'Forms'"
            :route="'/forms/requiring-attention'"
            :icon="'fa-notes-medical'"
          ></nav-tile>
        </div>

        <!--  -->
        <div
          class="col-6 col-md-3 mb-3 text-center"
          v-if="$can('view kitchen')"
        >
          <nav-tile
            :title="'Kitchen'"
            :route="'/kitchen/board'"
            :icon="'fa-hat-chef'"
          ></nav-tile>
        </div>

        <!--  -->
        <div
          class="col-6 col-md-3 mb-3 text-center"
          v-if="$can('manage forms')"
        >
          <nav-tile
            :title="'Treatments'"
            :route="'/treatments'"
            :icon="'fa-syringe'"
          ></nav-tile>
        </div>
      </div>

      <h4 class="text-success fw-bold my-4">Because you're an admin...</h4>

      <!-- @todo Are these perms correct? -->

      <div class="row">
        <div
          class="col-6 col-md-3 mb-3 text-center"
          v-if="$can('manage billing')"
        >
          <nav-tile
            :title="'Billing'"
            :route="'/billing'"
            :icon="'fa-file-invoice'"
          ></nav-tile>
        </div>

        <!--  -->
        <div
          class="col-6 col-md-3 mb-3 text-center"
          v-if="$can('manage forms')"
        >
          <nav-tile
            :title="'Forms'"
            :route="'/form-templates'"
            :icon="'fa-align-justify'"
          ></nav-tile>
        </div>

        <!--  -->
        <div
          class="col-6 col-md-3 mb-3 text-center"
          v-if="$can('view reporting')"
        >
          <nav-tile
            :title="'Reporting'"
            :route="'/reporting'"
            :icon="'fa-chart-bar'"
          ></nav-tile>
        </div>

        <!--  -->
        <div
          class="col-6 col-md-3 mb-3 text-center"
          v-if="$can('manage forms')"
        >
          <nav-tile
            :title="'Email Templates'"
            :route="'/email-templates'"
            :icon="'fa-file-alt'"
          ></nav-tile>
        </div>

        <!--  -->
        <div
          class="col-6 col-md-3 mb-3 text-center"
          v-if="$can('manage blog posts')"
        >
          <nav-tile
            :title="'Blog'"
            :route="'/posts'"
            :icon="'fa-blog'"
          ></nav-tile>
        </div>

        <!--  -->
        <div
          class="col-6 col-md-3 mb-3 text-center"
          v-if="$can('manage seo')"
        >
          <nav-tile
            :title="'SEO'"
            :route="'/seo'"
            :icon="'fa-analytics'"
          ></nav-tile>
        </div>

        <!--  -->
        <div
          class="col-6 col-md-3 mb-3 text-center"
          v-if="$can('manage users')"
        >
          <nav-tile
            :title="'Users'"
            :route="'/users'"
            :icon="'fa-users'"
          ></nav-tile>
        </div>

        <!--  -->
        <div
          class="col-6 col-md-3 mb-3 text-center"
          v-if="$can('manage roles')"
        >
          <nav-tile
            :title="'Roles'"
            :route="'/roles'"
            :icon="'fa-key'"
          ></nav-tile>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavTile from "../../components/NavTile";

export default {
  props: [],
  data() {
    return {
      hideMe: false,
      myname: "",
    };
  },
  computed: {
    user() {
      return this.$store.user;
    },
  },
  methods: {
    fetchUserName() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/user")
        .then(({ data }) => {
          this.myname = data.name;
        });
    },
    sendalert() {
      var data = {
        color: "danger",
        message:
          "some message lorem Lorem ipsum dolor sit amet consectetur, adipisicing elit. Possimus dignissimos repellendus quas officia minus, inventore impedit, provident aut dolores ipsam, praesentium rem ut nam repudiandae vero iusto sunt? Obcaecati, tempore!",
      };
      this.$EventBus.$emit("alert", data);
    },
  },
  beforeDestroy() {
    this.$store.sidebarOpen = true;
  },
  created() {
    this.fetchUserName();
  },
  mounted() {
    this.$store.sidebarOpen = false;
  },
  components: {
    NavTile,
  },
};
</script>

<style></style>
